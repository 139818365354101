$search-plp-top-banner-color: color(light);
$search-plp-top-banner-background: color(dark);
$search-plp-top-banner-category-name-text-transform: lowercase;
$search-plp-top-banner-category-name-display: inline-block;
$search-plp-top-banner-category-name-margin: rem-calc(25 4 26 25);
$search-plp-top-banner-category-name-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$search-plp-top-banner-category-name-with-category-cover-display: none;
$search-plp-top-banner-results-count-text-transform: $search-plp-top-banner-category-name-text-transform;
$search-plp-top-banner-results-count-display: $search-plp-top-banner-category-name-display;
$search-plp-top-banner-text-align: left;
$search-plp-top-banner-category-description-text-transform: lowercase;
$search-plp-top-banner-category-description-margin: rem-calc(0 0 15 25);

// Category Cover display
$search-plp-top-banner-category-cover-display: block;

@import "@lora/05-components/search/search-plp-top-banner";