// Title
$category-cover-title-font: normal #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$category-cover-title-font--large: $category-cover-title-font;
$category-cover-title-text-transform: lowercase;

//content
$category-cover-body-width--large: rem-calc(471);

// Description
$category-cover-description-font: 300 #{rem-calc(12)} / 1.4 $font-primary;
$category-cover-description-font--large: 300 #{rem-calc(14)} / 1.45 $font-primary;
$category-cover-description-text-transform: lowercase;
$category-cover-description-text-transform--large: $category-cover-description-text-transform;

@import "@lora/05-components/category-cover";